<template>
  <span>
    <a v-if="etat === ETAT_DEMANDE_ATTESTATION_EN_ATTENTE" style="color: orange"
      >En cours</a
    >
    <a v-else>Prête</a>
  </span>
</template>
<script>
export default {
  name: "EtatAttestationIndicator",
  props: {
    etat: { type: Number, default : () => 0 },
  },
};
</script>
