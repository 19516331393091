var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        {
          staticClass: "table_head",
          attrs: { type: "flex", justify: "space-around" }
        },
        [
          _c(
            "a-col",
            { staticClass: "mrgin_btm", attrs: { xs: 24, sm: 4 } },
            [
              !_vm.loading
                ? _c(
                    "a-button",
                    {
                      staticClass: "editable-add-btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.showModal }
                    },
                    [_vm._v("Demander Attestation")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mrgin_btm txt-end", attrs: { xs: 24, sm: 4 } },
            [
              _c("search-component", {
                on: {
                  change: function($event) {
                    return _vm.searchAttestation($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("CreateForm", {
        key: _vm.newDemandeAttestationComponentKey,
        ref: "CreateForm",
        attrs: { visible: _vm.visible, processing: _vm.processing },
        on: {
          cancel: _vm.handleCancel,
          close: _vm.handleClose,
          create: _vm.handleCreate
        }
      }),
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "center" } },
        [
          _c(
            "a-col",
            { attrs: { span: 22 } },
            [
              _c(
                "a-spin",
                { attrs: { tip: "Chargement...", spinning: _vm.loading } },
                [
                  _vm.dataSource.length
                    ? _c("a-table", {
                        attrs: {
                          bordered: "",
                          dataSource: _vm.dataSource,
                          columns: _vm.columns,
                          rowKey: _vm.id
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "row_index",
                              fn: function(text, record, index) {
                                return [_c("p", [_vm._v(_vm._s(index + 1))])]
                              }
                            },
                            {
                              key: "attestation",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    _vm._s(record.type_attestation.nom_fr) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "etat",
                              fn: function(text, record) {
                                return [
                                  _c("etat-attestation-indicator", {
                                    attrs: { etat: record.etat }
                                  })
                                ]
                              }
                            },
                            {
                              key: "dt_demande",
                              fn: function(text, record) {
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("date")(record.created_at)) +
                                      "\n          "
                                  )
                                ]
                              }
                            },
                            {
                              key: "operation",
                              fn: function(text, record) {
                                return [
                                  record.etat ===
                                  _vm.ETAT_DEMANDE_ATTESTATION_ACCEPTER
                                    ? _c(
                                        "a-tooltip",
                                        { attrs: { placement: "top" } },
                                        [
                                          _c("template", { slot: "title" }, [
                                            _c("span", [_vm._v("Imprimer")])
                                          ]),
                                          _c("a-icon", {
                                            staticClass: "action_icons",
                                            attrs: {
                                              type: "printer",
                                              theme: "twoTone"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.getDocument(record)
                                              }
                                            }
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  !record.etat
                                    ? _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title:
                                              "Etes-vous sûr de vouloir annuler la demande ?"
                                          },
                                          on: {
                                            confirm: function() {
                                              return _vm.onCancelDemande(
                                                record.id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticClass:
                                              "action_icons decission_btn",
                                            attrs: {
                                              type: "delete",
                                              theme: "twoTone",
                                              twoToneColor: "#eb2f96"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3157171206
                        )
                      })
                    : _c("EmptyResult")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("necessary-paiement-message", {
        attrs: { visible: _vm.paiementErrorMessageModalVisible },
        on: { close: _vm.closePaiementErrorModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }