<template>
  <div>
    <a-row type="flex" justify="space-around" class="table_head">
      <a-col :xs="24" :sm="4" class="mrgin_btm">
        <a-button
          class="editable-add-btn"
          type="primary"
          @click="showModal"
          v-if="!loading"
          >Demander Attestation</a-button
        >
      </a-col>
      <a-col :xs="24" :sm="4" class="mrgin_btm txt-end">
        <search-component v-on:change="searchAttestation($event)" />
      </a-col>
    </a-row>
    <CreateForm
      ref="CreateForm"
      :visible="visible"
      :processing="processing"
      @cancel="handleCancel"
      @close="handleClose"
      @create="handleCreate"
      :key="newDemandeAttestationComponentKey"
    />
    <a-row type="flex" justify="center">
      <a-col :span="22">
        <a-spin tip="Chargement..." :spinning="loading">
          <a-table
            bordered
            v-if="dataSource.length"
            :dataSource="dataSource"
            :columns="columns"
            :rowKey="id"
          >
            <template slot="row_index" slot-scope="text, record, index">
              <p>{{ index + 1 }}</p>
            </template>
            <template slot="attestation" slot-scope="text, record"
              >{{ record.type_attestation.nom_fr }}
            </template>

            <template slot="etat" slot-scope="text, record">
              <etat-attestation-indicator :etat="record.etat"/>
            </template>

            <template slot="dt_demande" slot-scope="text, record"
              >{{ record.created_at | date }}
            </template>
            <template slot="operation" slot-scope="text, record">
              <a-tooltip placement="top" v-if="record.etat === ETAT_DEMANDE_ATTESTATION_ACCEPTER">
                <template slot="title">
                  <span>Imprimer</span>
                </template>
                <a-icon
                  type="printer"
                  @click="getDocument(record)"
                  class="action_icons"
                  theme="twoTone"
                />
              </a-tooltip>
              <a-popconfirm
                v-if="!record.etat"
                title="Etes-vous sûr de vouloir annuler la demande ?"
                @confirm="() => onCancelDemande(record.id)"
              >
                <a-icon
                  type="delete"
                  class="action_icons decission_btn"
                  theme="twoTone"
                  twoToneColor="#eb2f96"
                />
              </a-popconfirm>
            </template>
          </a-table>
          <EmptyResult v-else />
        </a-spin>
      </a-col>
    </a-row>
    <necessary-paiement-message
      @close="closePaiementErrorModal"
      :visible="paiementErrorMessageModalVisible"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import  debounce  from "lodash-es/debounce";
import EtatAttestationIndicator from "@/components/common/EtatAttestationIndicator";
import CreateForm from "./AttestationEditor";
import SearchComponent from "@/components/common/Search";
import EmptyResult from "@/components/common/EmptyResult";
import constants from "@/const/const";
import NecessaryPaiementMessage from "@/components/common/NecessaryPaiementMessage";

const columns = [
  {
    title: "N°",

    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Attestation",
    scopedSlots: { customRender: "attestation" },
  },
  {
    title: "Organisme",
    dataIndex: "organisme.nom_fr",
  },
  {
    title: "Date demande",
    scopedSlots: { customRender: "dt_demande" },
  },
  {
    title: "Etat",
    scopedSlots: { customRender: "etat" },
  },
  {
    title: `${constants.OPERATION}`,
    scopedSlots: { customRender: "operation" },
  },
];
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 12,
      offset: 0,
    },
    sm: {
      span: 12,
      offset: 20,
    },
  },
};

export default {
  name: "AttestationList",
  created() {
    this.fetchData();
  },
  components: {
    CreateForm,
    SearchComponent,
    EmptyResult,
    NecessaryPaiementMessage,
    EtatAttestationIndicator
  },
  data() {
    this.searchAttestation = debounce(this.searchAttestation, 900);
    return {
      confirmDirty: false,
      autoCompleteResult: [],
      formItemLayout: formItemLayout,
      tailFormItemLayout: tailFormItemLayout,
      columns: columns,
      newDemandeAttestationComponentKey: 0,
      paiementErrorMessageModalVisible: false,
      visible: false,
      processing: false,
      loading: true,
      confirmLoading: false,
    };
  },
  computed: {
    ...mapState({
      dataSource: (state) => state.adherant_attestations.adherant_attestations,
      user: (state) => state.adherent.adherent,
      organismes: (state) => state.adherent_organismes.organismes,
    }),
  },
  methods: {
    getDemandeAttestationComponentKey() {
      this.newDemandeAttestationComponentKey = this.getRandomInt();
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    closePaiementErrorModal() {
      this.paiementErrorMessageModalVisible = false;
    },
    showPaiementErrorModal() {
      this.paiementErrorMessageModalVisible = true;
    },
    fetchData() {
      this.startLoading();
      this.$store.cache
        .dispatch("fetchAdherent")
        .then(() => {
          this.fetchUserAttestations();
          this.$store.cache.dispatch("fetchAdherentOrganismes");
        })
        .finally(() => this.stopLoading());
    },
    onCancelDemande(attestationId) {
      this.startLoading();
      this.delete(attestationId)
        .then(() => this.fetchUserAttestations())
        .finally(() => this.stopLoading());
    },
    moment,
    searchAttestation(q = "") {
      this.startLoading();
      this.fetchUserAttestations({ q: q }).finally(() => this.stopLoading());
    },
    getDocument(record) {
      this.startLoading();
      this.callPdfGenerator({
        id: record.user.id,
        type: 7, //Attestation,,
        organisation: this.user.organisation_id,
      })
        .catch(() => {
          this.$_throwAnError();
        })
        .finally(() => this.stopLoading());
    },
    closeModal() {
      this.visible = false;
      this.getDemandeAttestationComponentKey();
    },
    showModal() {
      if (!this.isUserAccepter(this.user.etat)) {
        this.$_throwAnError(
          "L'administration n'a pas encore accepté votre demande, vous ne pouvez pas ajouter un paiement."
        );
        return;
      }
      if (!this.isUserPaiementSettled(this.user)) {
        this.displayPaiementMandatoryError();
        return;
      }
      this.visible = true;
    },
    displayPaiementMandatoryError() {
      this.showPaiementErrorModal();
    },
    handleCreate() {
      this.startLoading();
      this.startProcessing();
      const form = this.$refs.CreateForm.form;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.add({ ...form.getFieldsValue() })
          .then(() => {
            this.fetchUserAttestations();
            this.handleClose();
            form.resetFields();
          })
          .finally(() => {
            this.stopLoading();
            this.stopProcessing();
          });
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleClose(e) {
      this.visible = false;
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    ...mapActions({
      fetchUserAttestations: "fetchAdherentAttestations",
      getAdherent: "fetchAdherent",
      add: "storeAdherentAttestations",
      delete: "destroyAdherentAttestation",
      callPdfGenerator: "callPdfGenerator",
    }),
  },
};
</script>
<style scoped>
.txt-end {
  text-align: end;
}
.table_head {
  margin-bottom: 2%;
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
.decission_btn {
  text-decoration: underline;
}
.action_icons {
  margin: 4px;
  margin-left: 20px;
  font-size: large;
}
.mrgin_btm {
  margin-bottom: 3%;
}
</style>
