<template>
    <div>
        <h1 style="margin-left:4%;margin-bottom:4%">Demande d'attestation</h1>  
        <AttestationList></AttestationList>
    </div>
</template>

<script>
    import AttestationList from "./AttestationList"
export default {
    name : 'AttestationIndex',
    components : {AttestationList}
}
</script>
