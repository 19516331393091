var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.etat === _vm.ETAT_DEMANDE_ATTESTATION_EN_ATTENTE
      ? _c("a", { staticStyle: { color: "orange" } }, [_vm._v("En cours")])
      : _c("a", [_vm._v("Prête")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }